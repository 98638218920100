<template>
  <main play-viewer :class="[type, moreOpen ? 'more-open' : '']">
    <div class="viewer">
      <Component :is="comp" :content="content" :key="`${type}-${apiData.sq}`" @next="prevPage" @prev="nextPage" @full="fullScreen"></Component>
      <div class="info" :class="{hide: infoHide}">
        <div class="inner">
          <div class="text">
            <p>{{ codeLabel(code) }}</p>
            <p>{{ enterToBr(info.title) }}</p>
          </div>
          <a @click="moreOpen=true">
            <span v-t:viewMore></span>
            <i></i>
          </a>
        </div>
      </div>
      <a class="close" @click="close"></a>
      <div class="page-move" :class="{full: infoHide}">
        <a class="prev" @click="nextPage()" :class="{ disabled: isEmpty(next) }"></a>
        <a class="next" @click="prevPage()" :class="{ disabled: isEmpty(prev) }"></a>
      </div>
    </div>

    <div class="more-info">
      <div class="inner">
        <a class="more-close" @click="moreOpen=false"></a>
        <SnsShare type="play-main" :value="apiData"></SnsShare>
        <p class="depth">{{ codeLabel(code) }}</p>
        <p class="title" v-html="enterToBr(info.title)"></p>
        <p class="content" v-html="enterToBr(info.content)"></p>
        <a class="link" :href="info.link" :target="info.target === 'Yes' ? '_blank' : '_self'" v-if="info.link"><span>{{ info.linkText }}</span><i></i></a>
      </div>
    </div>
  </main>
</template>

<script>
import _ from 'lodash';
import oax from '@/utils/oax';
import PlayShorts from '@/views/components/PlayShorts';
import PlayWide from '@/views/components/PlayWide';
import SnsShare from "@/views/components/SnsShare";
import { i18n } from '@/plugins/i18n';

export default {
  name: 'play-viewer',
  components: {SnsShare, PlayWide, PlayShorts },
  data() {
    return {
      apiData: null,
      siblings: null,
      moreOpen:false,
      infoHide:false,
    };
  },
  metaInfo() {
    if (!this.apiData) return null;
    return {
      title: this.apiData.title,
      meta: [
        { vmid: 'title', content: this.apiData.metaTitle || this.$t('meta.title'), },
        { vmid: 'description', content: this.apiData.metaDesc || this.$t('meta.description'), },
        { vmid: 'image', content: this.apiData.metaImage ? `${process.env.VUE_APP_CDN_HOST}/${this.mediaVal(this.apiData.metaImage).location}` : '/img/og.png', },
        { vmid: 'path', content: this.$route.path.replace(new RegExp(`^/(${i18n.routeMatches()})`), '') },
      ],
    }
  },
  computed: {
    type() {
      return this.apiData.type;
    },
    comp() {
      return this.type === 'play-wide' ? 'PlayWide' : 'PlayShorts';
    },
    info() {
      return this.apiData.info;
    },
    content() {
      return this.apiData.content;
    },
    code() {
      return this.resetPage?.code || this.apiData.priorityCategory;
    },
    resetPage() {
      return this.$store.state.resetPage;
    },
    prev() {
      return this.siblings.prev?.slug || '';
    },
    next() {
      return this.siblings.next?.slug || '';
    },
  },
  methods: {
    close() {
      if (this.$store.state.browser.isFirst || window.history.length <= 1) {
        this.localeLink(this.codeRouter(this.code));
        return;
      }
      this.$router.go(-1);
    },
    nextPage(){
      if(!this.isEmpty(this.next)){
        this.$router.replace(this.next);
      }else{
        alert('다음 글이 없습니다.')
      }
    },
    prevPage(){
      if(!this.isEmpty(this.prev)){
        this.$router.replace(this.prev);
      }else{
        alert('이전 글이 없습니다.')
      }
    },
    fullScreen(){
      if(!this.moreOpen) this.infoHide = !this.infoHide;
    }
  },
  mounted() {
    this.$store.commit('theme', 'viewer');
  },
  beforeDestroy() {
    this.$store.commit('resetPage', null);
  },
  async asyncData({ route, store }) {
    const apiData = (await oax.get(`/api/article/${route.meta.root}/${encodeURIComponent(route.params.slug)}`,
        { lang: route.params.locale || 'ko', category: route.query.code })).data;
    if (!apiData) throw new Error("404");

    const category = store.state.resetPage?.code || apiData.priorityCategory;
    let siblings = (await oax.get(`/api/article/${route.meta.root}/siblings`,
        { ..._.pick(apiData, ['lang','type','sq','publishDtt']), category })).data;
    siblings = _.mapValues(siblings, e => _.pickBy(e, _.identity()));

    const contents = JSON.parse(apiData.contents);
    apiData.info = contents.info;
    apiData.content = contents.content;

    return { apiData, siblings };
  }
};
</script>